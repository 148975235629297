import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { css, styled } from "styled-components";
import Image from "next/image";
import { MOBILE_BREAKPOINT } from "@rimo/ui-old";

export const Usecases = () => {
  const { customT } = useClientTranslation();
  return (
    <Container>
      <Heading>Usecases</Heading>
      <SubHead>Craft Any Writing, Unleash Every Possibility</SubHead>
      <ListWrapper>
        <List>
          <ListItem>
            <UsecaseImageWrapper>
              <UsecaseImage src="/assets/about_ai_editor/usecase1.png" alt="AIEditor-PC" width={200} height={200} />
            </UsecaseImageWrapper>
            <UsecaseContent>
              <UsecaseTitle>Professional Writing</UsecaseTitle>
              <UsecaseDesc>
                Struggling with transcription and article crafting? Rimo turns your spoken insights into text, freeing
                time for deeper research.
              </UsecaseDesc>
            </UsecaseContent>
          </ListItem>
          <ListItem>
            <UsecaseImageWrapper>
              <UsecaseImage src="/assets/about_ai_editor/usecase2.png" alt="AIEditor-PC" width={200} height={200} />
            </UsecaseImageWrapper>
            <UsecaseContent>
              <UsecaseTitle>Casual Blogging</UsecaseTitle>
              <UsecaseDesc>
                Overwhelmed by turning thoughts into text? Speak casually to Rimo, and watch your ideas unfold into
                compelling blog posts.
              </UsecaseDesc>
            </UsecaseContent>
          </ListItem>
          <ListItem>
            <UsecaseImageWrapper>
              <UsecaseImage src="/assets/about_ai_editor/usecase3.png" alt="AIEditor-PC" width={200} height={200} />
            </UsecaseImageWrapper>
            <UsecaseContent>
              <UsecaseTitle>Organizing Ideas</UsecaseTitle>
              <UsecaseDesc>
                Juggling disjointed ideas? Discuss them with Rimo for clearer structure, making sharing and refining
                your thoughts a breeze.
              </UsecaseDesc>
            </UsecaseContent>
          </ListItem>
        </List>
        <TagList>
          {tags.map((tag, i) => (
            <TagItem key={i}>{customT(tag)}</TagItem>
          ))}
          <TagItem key={tags.length} $light={true}>
            And many more...
          </TagItem>
        </TagList>
      </ListWrapper>
    </Container>
  );
};

const tags = [
  "Business reports",
  "Academic papers",
  "Professional articles",
  "Press releases",
  "Marketing materials",
  "Product catalogs",
  "Internal memos",
  "Newsletters",
  "Blogs",
  "Social media posts",
  "Educational materials",
];

const Container = styled.div`
  margin-top: 7rem;
  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    row-gap: 3.75rem;
    padding-inline: 1rem;
    margin-top: 4.75rem;
    scroll-margin-top: 2rem;
  }
`;

const Heading = styled.div`
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  margin: 1rem 0 0.6875rem;

  background: linear-gradient(45deg, #ffaf00 0%, #e50000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 1rem;
  }
`;

const SubHead = styled.h4`
  font-size: 1.625rem;
  line-height: 2rem;
  text-align: center;
  margin: 0 0 3.75rem;
  color: #444444;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
`;

const ListWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  margin-top: 2rem;

  @media (width <= ${`${MOBILE_BREAKPOINT}px`}) {
    grid-template-columns: 1fr;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #dddddd;
  border-radius: 1rem;
  overflow: hidden;
`;

const UsecaseImageWrapper = styled.div`
  width: 100%;
  height: 200px;
`;

const UsecaseImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const UsecaseContent = styled.div`
  padding: 1.5rem 1rem;
`;

const UsecaseTitle = styled.p`
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.67875rem;
  color: #222222;
  margin-bottom: 0.625rem;
`;

const UsecaseDesc = styled.p`
  font-size: 0.9375rem;
  line-height: 1.375rem;
  text-align: left;
  color: #444444;
`;

// Tag を横並びで改行して表示する
const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 40px;
  justify-content: center;
`;

const TagItem = styled.div<{ $light?: boolean }>`
  font-size: 18px;
  line-height: 22px;
  padding: 8px 12px 8px 12px;
  border-radius: 60px;
  border: 1px;
  border: 1px solid #dddddd;
  color: #222222;

  ${(p) =>
    p.$light &&
    css`
      color: #666666;
      border-color: #dddddd;
    `}
`;
